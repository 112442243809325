let initApiBase;
let initIsStaging;
let splitUrl = window.location.hostname.split(".");

if(process.env.NODE_ENV === "production"){
    if( splitUrl[ 2 ] === "fe" ){
        initIsStaging = true;
    }else{
        initIsStaging = false;
    }
    initApiBase = window.location.hostname;
}else{
    initApiBase = "http://localhost:8080";
    initIsStaging = true;
}

const apiBase = initApiBase;
const isStaging = initIsStaging;


module.exports = {
    // ports
    serverPort: 8080,
    staging: isStaging,
    pusherClientId: "c5350c80e013ebed7175",
    pusherRegion: "eu",
    //This app url
    appURL: apiBase,
};
