import { TextField } from "@mui/material";
//import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import AttachmentForm from "../pages/supportTicket/attachments/AttachmentForm";
import AttachmentList from "../pages/supportTicket/attachments/AttachmentList";

//import AppButton from "../../common/AppButton";
//import FileBase64 from "react-file-base64";

export default function TicketCreateForm(props) {

    return (
        <>
            <div className="block">
                <div className="form-row">
                    <div className="form-group col-lg-4">
                        <TextField
                            label="Ticket Name"
                            name="ticket_name"
                            value={props.data.ticket_name}
                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                            placeholder="Give the ticket a name..."
                            fullWidth
                            disabled={props.type === "edit"}
                        />
                    </div>
                </div>


                <div className="form-row">
                    <div className="form-group col-lg-12">
                        <TextField
                            label="Ticket Description"
                            name="notes"
                            value={props.data.notes}
                            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                            placeholder="Any additional notes? Add them here!..."
                            multiline
                            disabled={props.type === "edit"}
                            fullWidth
                        />
                    </div>
                </div>
            </div>
            {props.type !== "create" &&
                <>
                <div className="block">
                    <div className="form-row">
                        <div className="form-group col-lg-4">
                            <TextField
                                label="Ticket Status"
                                name="status_label"
                                value={props.data.current_status ? "Closed" : "Open" }
                                onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                fullWidth
                                multiline
                                disabled={props.type === "edit"}
                            />
                        </div>
                        <div className="form-group col-lg-12">
                            <TextField
                                label="Comment For Unity"
                                name="customer_update"
                                value={props.data.customer_update}
                                onChange={(e) => props.handleChange(e.target.name, e.target.value)}
                                multiline
                                disabled={props.type !== "edit"}
                                fullWidth
                            />
                        </div>
                    </div>
                </div>
                <div className="block">
                    <AttachmentForm
                        context={props.context}
                        ticket_id={props.data.id}
                    />
                </div>
               
                <div className="block">
                    <AttachmentList
                        ticket_id={props.data.id}
                    />
                </div> 
                </>
            }
            
            {
                props.type == "create" && (
                    <div className="block">
                        {/* <FileBase64 multiple={true} onDone={(e) => props.setDocuments(e)} /> */}
                        <i>You may upload attachements once you have submitted your ticket</i>
                    </div>
                )
            }


            {props.data.customer_documents && props.data.customer_documents.length > 0 &&
                <>
                    {props.data.customer_documents.map((item, key) => {
                        return <div key={key}>Document</div>
                    })}
                </>
            }


        </>
    );
}
