import React, { useState } from "react";
import { Button, CircularProgress  } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { DocumentVaultAPI, TicketApi } from "@unity/components";
import UploadFileIcon from '@mui/icons-material/UploadFile';


// import { storeDocuments } from "../../../../api/documents"
// import { ticketDocumentStore } from "../../../../api/ticket";

//import FileBase64 from "react-file-base64";
//import { supportAppUUID } from "../../../../config";

export default function AttachmentForm(props) {
    const [uploadData, setUploadData] = useState({});
    const [uploading, setUploading] = useState(false);

    const handleUpload = async (e) => {
        setUploadData({
            application_ref: props.context.auth.app_uuid,
            documents: e,
            public: 0
        });
    };

    const handleSave = async () => {
        setUploading(true);
        if( typeof uploadData.documents === undefined ) {
            alert("Please Select a File First");
            setUploading(false);
        }

        const res = await storeDocuments(uploadData);

        // Create TicketDocument Records in TicketAPI
        if( Array.isArray( res.data ) )
        {
            res.data.map(async (doc) => {
                var payload = {
                    "document_id": doc.id,
                    "document_name": doc.name,
                    "ticket_id": props.ticket_id
                }
                const docRes = await ticketDocumentStore(payload);
            })
        }
        setUploading(false);
        window.location.reload(true);
    }


    const handleInputChange = async (event) => {

        const files = event.target.files;
        let reader = new FileReader();
        let toUpload = []

        for (let i = 0; i < files.length; i++) {
            toUpload.push( new Promise((resolve, reject) => {
                reader.readAsDataURL(files[i]);
                reader.onload = (e) => {
                    resolve({
                        base64: e.target.result,
                        name: files[i].name,
                        type: files[i].type,
                        size: files[i].size
                    });
                }
            }))
        }
        return toUpload
    };

    const storeDocument = async (obj, lineIndex) => {

        const unresolvedPromise = await handleInputChange(obj)

        Promise.all(unresolvedPromise).then(value => {

            return  DocumentVaultAPI.storeDocument({ documents: value, application_ref: props.context.auth.app_uuid, public: 0});
        }).then( res => {

            if (Array.isArray(res.data)) {
                res.data.map(async (doc) => {
                    var payload = {
                        "document_id": doc.id,
                        "document_name": doc.name,
                        "ticket_id": props.ticket_id
                    }
                    const docRes = await TicketApi.ticketDocumentStore(payload);
                })
            }
            setUploading(false);
            window.location.reload(true);

        }
        )

    };

    return (
        <>
            <div className="form-row">
                <div className="form-group col-lg-4">
                    <i>Max Size 5MB</i>
                </div>
                <div className="form-group col-lg-4">
                    
                    <Button
                        variant="contained"
                        component="label"
                        onChange={(evt) => {
                            storeDocument(evt);
                        }}
                        color="primary"
                    >
                        <UploadFileIcon />
                        <input
                        type="file"
                        hidden={true}
                    />
                    </Button>

                </div>
            </div>
        </>
    )
}