import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { FusionAuthApi, AppHeaderComponent, ApiLoaderComponent, PortalApi, DocumentVaultAPI, LoadingComponent, TicketApi } from "@unity/components";
// import { saveTicket } from "../../api/ticket";
// import ApiLoader from "../../components/common/ApiLoader";
// import AppButton from "../../components/common/AppButton";
import AuthContext from "../../services/AuthContext";
import TicketCreateForm from "../../modules/TicketCreateForm";

export default function TicketsCreate(props) {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState({ status: false, data: false });

    let history = useHistory();

    const context = useContext(AuthContext);

    const handleSave = async () => {
        setLoading({ status: true, data: "Saving Records.. Please Wait !" });
        data['partial'] = 1;
        data['customer_id'] = props.context.auth.client;
        const res = await PortalApi.storeTicket(data);

        setLoading({ status: true, data: "Success, Please Wait ...." });

        setTimeout(() => {
            history.push("/submit-ticket/index");
        }, 4000);
    };

    const handleChange = (name, value) => {
        setData({ ...data, [name]: value });
    };


    const setDocuments = (e) => {
        setData({ ...data, "customer_documents": e });
    }


    return (
        <>
            <AppHeaderComponent
                context={props.context}
                saveBtn={handleSave}
                theme={props.context.theme}
                name={"submit-ticket"}
                spacer={true}
                subpage="create"
            />

            <TicketCreateForm
                handleChange={handleChange}
                data={data}
                type="create"
                setDocuments={setDocuments}
                context={context}
            />

            <ApiLoaderComponent
                status={loading.status}
                data={loading.data}
            />
        </>
    );
}
