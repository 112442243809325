import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { FusionAuthApi, AppHeaderComponent, ApiLoaderComponent, PortalApi, DocumentVaultAPI , LoadingComponent } from "@unity/components";
import { CanCreate, CanModuleAdmin } from "../../services/Permissions";
import { useHistory } from "react-router-dom";
import AuthContext from "../../services/AuthContext";
import ModuleButton from "../../common/ModuleButton";
import DataTable from "../../common/DataTable";

export default function SupportTicket(props) {

  //change the permission to feedback once I have permission, and change in the app header
  const create = CanCreate("submit-ticket");
  const admin = CanModuleAdmin("submit-ticket");

  const context = useContext(AuthContext);
  const [data, setData] = useState(false);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState(true);// Flag to switch between unpaginated and paginated data!
  const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
  const [searchText, setSearchText] = useState('');
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!

  const [openTicketData, setOpenTicketData] = useState([])
  const [closedTicketData, setClosedTicketData] = useState([])
  const [allTicketData, setAllTicketData] = useState(false)

  const history = useHistory();


  const handleLinkClick = (event, params, path) => {
    event.preventDefault();
    history.push({
      pathname: `/submit-ticket/${path}/${params.id}`,
      state: { ...params }
    });
};

  const handlePageChange = (page) => {
    setPage(page);

    if ( pagination ) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);

    if(pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };


  const getTickets = async () => {
    const res = await PortalApi.getTicketByCustomer(props.context.auth.client);
    filterInformation(res.data)

    setAllTicketData(res.data)
    setData(res.data);

  };

  const filterInformation = (tableData) => {
    let openTickets = [];
    let closedTickets = [];

    tableData.map( (e) => {
      if(e.current_status){
        closedTickets.push(e)
      }else{
        openTickets.push(e)
      }
    })
    setOpenTicketData( openTickets )
    setClosedTicketData( closedTickets )
  }

  useEffect(() => {
    getTickets();
  }, []);

  const columns = [
    { field: "id", headerName: "Ticket ID", flex: 0.3 },
    { field: "created_at", headerName: "Date Created", flex: 0.5 },
    { field: "updated_at", headerName: "Last Updated", flex: 0.5 },
    { field: "ticket_name", headerName: "Ticket Name", flex: 1 },
    { field: "current_status", headerName: "Ticket Status", flex: 0.4,
    renderCell: (status) => {
      return (
        <div>
          {status.value ? "Closed" : "Open"}
        </div>
      );
    },
  
  },
    { field: "Action", headerName: "Action", flex: 0.3, 
    renderCell: (cellValues) => {
      return (
        <div>
          <ModuleButton
                btnIcon="edit"
                style={{
                  background: "orange",
                  color: "white",
                  marginLeft: 10
                }}
                onClick={(event) => handleLinkClick(event, cellValues.row, "edit")}
                //onClick={(event) => handleVersionUpdate(cellValues.row)}
                tip="Update"
              />
        </div>
      );
    },
  }
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 680
  };



  return (
    <>
      <AppHeaderComponent
        addNew={() => <Link to={"/submit-ticket/create"}>
          <ModuleButton
            text="Create New"
            btnIcon="add"
            stylename="primary"
            style={
              {
                background: props.context.theme.top_menu.button_background,
                color: props.context.theme.top_menu.button_font,
              }
            }
          />
        </Link>}
        context={props.context}
        theme={props.context.theme}
        name={"submit-ticket"}
        spacer={true}
      />

      {data ?
        <div className="container">
          <div className="block" style={{ marginBottom: 10, textAlign: "right" }}>
            <ModuleButton
              text="Open Tickets"
              btnIcon=""
              style={{ color: "white", background: "blue", marginLeft: 10 }}
              onClick={() => { setData( openTicketData ) }}
            />
            <ModuleButton
              text="Closed Tickets"
              btnIcon=""
              style={{ color: "white", background: "blue", marginLeft: 10 }}
              onClick={() => { setData( closedTicketData ) }}
            />
            <ModuleButton
              text="All Tickets"
              style={{ color: "white", background: "blue", marginLeft: 10 }}
              onClick={() => { setData( allTicketData ) }}
            />
          </div>
          <DataTable
            columns={columns}
            data={data}
            pagination={pagination}
            options={options}
            allowDelete={false}
            handleDeleteArray={false}
            searchText={searchText}
            setSearchText={setSearchText}
            fetchTrigger={fetchTrigger}
            setFetchTrigger={setFetchTrigger}
          />
        </div>

        :
        <LoadingComponent color={props.context.theme.sidebar.background} />
        
    }

    </>
  );
}

