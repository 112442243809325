import React, { useState, useEffect, Suspense } from "react";
import { BusinessApi , AppHeaderComponent } from "@unity/components";
import { CircularProgress, Fade, LinearProgress, Tab, Tabs, Typography } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';

import BusinessIcon from '@mui/icons-material/Business';
import CabinIcon from '@mui/icons-material/Cabin';
import HistoryIcon from '@mui/icons-material/History';

const BusinessView = React.lazy(() => import("./view/business"));
const BusinessSites = React.lazy(() => import("./view/sites"));
const BusinessChanges = React.lazy(() => import("./view/changes"));

export default function Manage({context, route}) {
    const businessId = context.auth.client;
    const [business, setBusiness] = useState(false);
    const [tab, setTab] = useState(0);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const getBusiness = async () => {
        setLoading("Loading Business");
        try {
            const res = await BusinessApi.businessShow(businessId);
            if(!res.success || res.data == undefined || res.data == null) throw "Call Failed";
            setBusiness(res.data);
        } catch (error) {
            setError("Failed to Pull Business. Please Refresh and Try Again");
        }
        setLoading(false);
    }

    const getTab = () => {
        switch(tab) {
            case 0: return (
                <Suspense fallback={<Fade in><LinearProgress/></Fade>}>
                    <BusinessView 
                        business={business} 
                        context={context} 
                        getBusiness={getBusiness}
                    />
                </Suspense>
            )
            case 1: return ( 
                <Suspense fallback={<Fade in><LinearProgress/></Fade>}>
                    <BusinessSites 
                        business={business} 
                        context={context}
                        getBusiness={getBusiness}
                    />
                </Suspense>
            )
            case 2: return <Suspense fallback={<Fade in><LinearProgress/></Fade>}><BusinessChanges businessId={businessId} context={context}/></Suspense>
            default: return <Typography>😕 Unknown Tab</Typography>
        }
    }
    
    useEffect(() => {
        getBusiness();
    }, []);

    return (
      <>
        <div>
            <Tabs
                value={tab}
                onChange={(e, val) => {setTab(val)}}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                style={{
                    backgroundColor: context.theme.top_menu.background,
                    color: context.theme.top_menu.font,
                    backgroundClip: "inherit",
                    marginLeft: "-1.1%",
                    width: "101.99%"
                }}
            >
                <Tab icon={<BusinessIcon/>} label="Business"/>
                <Tab icon={<CabinIcon/>} label="Sites"/>
                <Tab icon={<HistoryIcon/>} label="Changes"/>
            </Tabs>
            {
                error && (
                    <div style={{ 
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <Typography variant="h2" gutterBottom><ErrorIcon/></Typography>
                        <Typography>{error}</Typography>
                    </div>
                )
            }
            {
                loading ? (
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <CircularProgress/>
                        <Typography>Loading...</Typography>
                    </div>
                ) : (
                    <>
                        {getTab()}
                    </>
                )
            }
        </div>
        </>
    );
}