import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { FusionAuthApi, AppHeaderComponent, ApiLoaderComponent , PortalApi , DocumentVaultAPI, BusinessApi } from "@unity/components";
import { CanCreate, CanModuleAdmin } from "../../services/Permissions";
import DocumentCard from "../../modules/DocumentCard";
import AuthContext from "../../services/AuthContext";


export default function Documents(props) {

  const [docs, setDocs] = useState([]);

  //change the permission to feedback once I have permission, and change in the app header
  const create = CanCreate("documents");
  const admin = CanModuleAdmin("documents");

  const getDocs = async () => {
    try {
      const res = await BusinessApi.businessShow(props.context.auth.client, {documents: 1});
      if(!res.success) throw "Call Error";
      if(res.data.documents == undefined) throw "Documents Not Found";

      const docRes = await DocumentVaultAPI.documentsCustomerIndex(props.context.auth.client);
      if(!docRes.success) throw "Call Error";
      
      setDocs([].concat(res.data.documents, docRes.data));
    } catch (error) {
        alert("Failed to Pull Business Documents");
    }
  };

  const getDoc = async (vault_id) => {
    const res = await DocumentVaultAPI.generateTempLink(vault_id)
    window.open( res.data, '_blank');
}

  useEffect(() => {
    getDocs();
  }, []);


  return (
    <>
      <AppHeaderComponent
        context={props.context}
        theme={props.context.theme}
        name={"documents"}
        spacer={true}
      />

      <div className="container">
        {docs && docs.length > 0 ? (
          <div className="row" style={{ marginTop: 100 }}>
            {docs.map((item, key) => {
              return <DocumentCard item={item} key={key} getDoc={getDoc} />;
            })}
          </div>
        ) : (
          <div className="container">
            <div className="row">
              <p>No Documents Yet</p>
            </div>
          </div>
        )}
      </div>

    </>
  );
}
