import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
// import { chatIndexByTicketArray, chatStore } from "../../api/livechat";
// import { getTicketShow, updateTicket } from "../../api/ticket";

import { FusionAuthApi, AppHeaderComponent, ApiLoaderComponent, PortalApi, DocumentVaultAPI, LoadingComponent, TicketApi, NotificationsApi } from "@unity/components";
import ModuleButton from "../../common/ModuleButton";
import TicketCreateForm from "../../modules/TicketCreateForm";
import ChatMessageItemCustomer from "./ChatMessageItem";
import ChatMessageItemUser from "./ChatMessageItemUser";
import "./chat.css";
import AuthContext from "../../services/AuthContext";

export default function TicketsEdit(props) {

    const [data, setData] = useState(false);
    const [chat, setChat] = useState(false);
    const context = useContext(AuthContext);
    const [loading, setLoading] = useState({ status: false, data: false });
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    const handleChange = (name, value) => {
        setData({ ...data, [name]: value });
    };


    const setDocuments = (e) => {
        setData({ ...data, "customer_documents": e });
    }

    const handleMessageSend = async () => {
        var obj = {
            chat_id: chat.id,
            body: message,
            is_customer: true,
            username: props.context.auth.name,
            support_email: true,
            company: props.context.auth.client
        }

        const r = await NotificationsApi.chatStore(obj);
        setMessage("")
    }

    let history = useHistory();

    const handleSave = async () => {

        setLoading({ status: true, data: "Saving Records.. Please Wait !" });
        const res = await PortalApi.updateTicket(props.route.match.params.id, data);

        setLoading({ status: true, data: "Success, Please Wait ...." });

        setTimeout(() => {
            history.push("/submit-ticket/index");
        }, 4000);
    };


    const getTickets = async () => {

        const res = await PortalApi.getTicketShow(props.route.match.params.id)
        const chatData = await NotificationsApi.chatIndexByTicketArray({ reference_array: [props.route.match.params.id] });

        let channel = props.pusherConnection.subscribe(props.context.auth.tenant_uuid);

        setData(res.data)
        setChat(chatData.data.length > 0 ? chatData.data[0] : chatData.data)

        {
            chatData.data.length > 0 &&
                channel.bind(`chat-ticket-${chatData.data[0].id}`, async function (e) {
                    const chatData = await NotificationsApi.chatIndexByTicketArray({ reference_array: [props.route.match.params.id] });
                    setChat(chatData.data[0])
                });
        }
    };


    useEffect(() => {
        getTickets();
    }, []);



    if (data) {
        return (
            <>
                <AppHeaderComponent
                    saveBtn={handleSave}
                    context={props.context}
                    theme={props.context.theme}
                    name={"submit-ticket"}
                    spacer={true}
                    subpage="edit"
                />

                <div className="container">
                    <div style={{ marginTop: 10 }}>
                        <div className="block" style={{ marginBottom: 10 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.customer_closed}
                                        onChange={(e) => setData({ ...data, "customer_closed": e.target.checked })}
                                        color="primary"
                                    />
                                }
                                label={data.customer_closed ? "Please now update your ticket to close" : "Request Ticket Closure"}
                            />
                        </div>
                    </div>
                    <TicketCreateForm
                        handleChange={handleChange}
                        data={data}
                        type="edit"
                        setDocuments={setDocuments}
                        setOpen={setOpen}
                        context={context}
                    />


                    <div className="block" id="chat-window" style={{ height: "100%", padding: 20, boxShadow: "none", overflowY: "scroll", paddingBottom: 90, paddingTop: 10 }}>
                        <p className="chat-title" style={{ marginBottom: 30 }}>Ticket {chat.reference_id}</p>
                        {chat && chat.messages.map((item, key) => {
                            if (!item.is_customer) {
                                return <ChatMessageItemCustomer data={item} />
                            } else {
                                return <ChatMessageItemUser data={item} />
                            }
                        })}

                        <div className="chat-actions">
                            <textarea onChange={(e) => setMessage(e.target.value)} value={message} type="text" placeholder="Type message" className="input-message" />
                            <ModuleButton text="send" btnIcon="send" onClick={() => handleMessageSend()} style={{ background: '#7f7fff', color: 'white' }} />
                        </div>

                    </div>

                </div>

                <ApiLoaderComponent color={'blue'} status={loading.status} data={loading.data} />

            </>
        );
    } else {
        return <LoadingComponent color={props.context.theme.sidebar.background} />
    }
}
